import { template as template_047607b2f45c41f9ab8d38d21085ea66 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_047607b2f45c41f9ab8d38d21085ea66(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
