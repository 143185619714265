import { template as template_d20257de173640d1b6cb345f65678388 } from "@ember/template-compiler";
const FKText = template_d20257de173640d1b6cb345f65678388(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
