import { template as template_7fb80709f90f4a628c0a3dd0ea00b46f } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_7fb80709f90f4a628c0a3dd0ea00b46f(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
