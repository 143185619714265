import { template as template_f6b0c72a92a4450b900ab3c8365130df } from "@ember/template-compiler";
const FKControlMenuContainer = template_f6b0c72a92a4450b900ab3c8365130df(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
